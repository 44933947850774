/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-17",
    versionChannel: "nightly",
    buildDate: "2024-08-17T00:05:11.451Z",
    commitHash: "d885697d9b1060ae0886b22fe0d12b6c6e56bd5d",
};
